import { toast } from "react-toastify";

class SnackbarHandler {

  errorToast = (message: string) => {
    toast(message, { position: "top-right", type: "error" });
  };

  successToast = (response: string) => {
    toast(response, { position: "top-right", type: "success" });
  };

  statusToast = (text: string) => {
    toast(text, { position: "top-right", type: "success" });
  };

  normalToast = (text: string) => {
    toast(text, { position: "top-right", type: "default" });
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SnackbarHandler();
